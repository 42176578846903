export var StatusTypes;
(function (StatusTypes) {
    StatusTypes["initial"] = "initial";
    StatusTypes["pending"] = "pending";
    StatusTypes["fulfilled"] = "fulfilled";
    StatusTypes["rejected"] = "rejected";
})(StatusTypes || (StatusTypes = {}));
export const getInitialStatus = (value, promise) => {
    if (value instanceof Error)
        return StatusTypes.rejected;
    if (value !== undefined)
        return StatusTypes.fulfilled;
    if (promise)
        return StatusTypes.pending;
    return StatusTypes.initial;
};
export const getIdleStatus = (value) => {
    if (value instanceof Error)
        return StatusTypes.rejected;
    if (value !== undefined)
        return StatusTypes.fulfilled;
    return StatusTypes.initial;
};
export const getStatusProps = (status) => ({
    status,
    isInitial: status === StatusTypes.initial,
    isPending: status === StatusTypes.pending,
    isLoading: status === StatusTypes.pending,
    isFulfilled: status === StatusTypes.fulfilled,
    isResolved: status === StatusTypes.fulfilled,
    isRejected: status === StatusTypes.rejected,
    isSettled: status === StatusTypes.fulfilled || status === StatusTypes.rejected,
});
